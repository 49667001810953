<template>
  <div>
    <v-card flat dense>
      <v-form ref="form" lazy-validation>
        <v-flex xs12>
          <v-layout row wrap align-center>
            <v-flex xs12 md6>
              <v-text-field
                v-model="account"
                label="Account"
                class="mx-2"
                required
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
                v-model="payor"
                label="Payor"
                class="mx-2"
                required
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                v-model="new_amount"
                :min="0"
                type="number"
                label="Amount"
                class="mx-2"
                required
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
<!--            <v-flex xs12 md2>-->
<!--              <v-text-field v-model="billers_amount_fee"-->
<!--                            label="Billers Fee"-->
<!--                            class="mx-2"-->
<!--                            :min="0"-->
<!--                            type="number"-->
<!--                            required-->
<!--                            :rules="rules.default_max_45_character_and_no_empty_rule"-->
<!--                            @keyup="calculate_charges"></v-text-field>-->
<!--            </v-flex>-->
<!--            <v-flex xs12 md2>-->
<!--              <v-text-field v-model="processing_amount_fee"-->
<!--                            label="Processing Fee"-->
<!--                            class="mx-2"-->
<!--                            :min="0"-->
<!--                            type="number"-->
<!--                            required-->
<!--                            :rules="rules.default_max_45_character_and_no_empty_rule"-->
<!--                            @keyup="calculate_charges"></v-text-field>-->
<!--            </v-flex>-->
<!--            <v-flex xs12 md2>-->
<!--              <v-text-field v-model="amount_fee"-->
<!--                            label="Fee"-->
<!--                            class="mx-2" required-->
<!--                            readonly></v-text-field>-->
<!--            </v-flex>-->
<!--            <v-flex xs12 md2>-->
<!--              <v-text-field v-model="total_amount"-->
<!--                            label="Total"-->
<!--                            class="mx-2" required-->
<!--                            readonly></v-text-field>-->
<!--            </v-flex>-->

            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_payments" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-layout>
        </v-flex>
      </v-form>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      is_customize_amount: Boolean,
      fund_particulars_id: Number,
      fund_particulars_pricing: Array,
      option: String,
      particulars: String,
      category: String,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        payor: '',
        new_amount: 0,
        amount_fee: 0,
        billers_amount_fee: 0,
        processing_amount_fee: 0,
        total_amount: 0,
        account: '',
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',

        reference_no: '',
      }
    },
    // watch: {
    //   fund_particulars_id: function () {
    //     this.calculate_charges()
    //   },
    // },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'month_of', 'user_id', 'is_franchise', 'branch', 'branch_address', 'branch_contact_no']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('fund_liquidation', ['save_fund_liquidation']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      // calculate_charges() {
      //   var amount = parseFloat(this.new_amount)
      //   var is_franchisess = this.is_franchise
      //   var amount_feeee = 0
      //   this.fund_particulars_pricing.forEach(function (item) {
      //     if (parseFloat(item.to_amount) === 0) {
      //       if (amount >= parseFloat(item.from_amount)) {
      //         if (parseInt(item.is_percentage)===1){
      //           if (is_franchisess && parseInt(item.is_franchise) === 1) {
      //             amount_feeee = amount*parseFloat(item.amount_fee)
      //           } else {
      //             amount_feeee = amount*parseFloat(item.amount_fee)
      //           }
      //         }else{
      //           if (is_franchisess && parseInt(item.is_franchise) === 1) {
      //             amount_feeee = parseFloat(item.amount_fee)
      //           } else {
      //             amount_feeee = parseFloat(item.amount_fee)
      //           }
      //         }
      //       }
      //     } else {
      //       if (amount >= parseFloat(item.from_amount) && amount <= parseFloat(item.to_amount)) {
      //         if (is_franchisess && parseInt(item.is_franchise) === 1) {
      //           amount_feeee = parseFloat(item.amount_fee)
      //         } else {
      //           amount_feeee = parseFloat(item.amount_fee)
      //         }
      //       }
      //     }
      //
      //   })
      //   this.amount_fee = amount_feeee;
      //
      //   this.amount_fee = (parseFloat(this.amount_fee) - (parseFloat(this.billers_amount_fee) + parseFloat(this.processing_amount_fee)))
      //   if (parseFloat(this.amount_fee) < 0) {
      //     this.amount_fee = 0
      //   }
      //   this.total_amount = parseFloat(this.new_amount) + parseFloat(this.billers_amount_fee) + parseFloat(this.processing_amount_fee) + parseFloat(this.amount_fee);
      // },
      save_payments() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var proceed = true
          if (this.balance <= 0) {
            proceed = false
            this.alert = true
            this.alert_message = 'Insufficient Balance'
            this.saving = false
          }
          if (parseFloat(this.new_amount) < 1) {
            proceed = false
            this.alert = true
            this.alert_message = 'Please Check the Amount!'
            this.saving = false
          }
          //if no error above then proceed
          if (proceed) {
            this.save_fund_liquidation({
              branch_id: this.branch_id,
              maker_id: this.user_id,
              category: this.option,
              fund_particulars_id: this.fund_particulars_id,
              reference_no: this.reference_no,
              month_of: this.month_of,
              fee: this.amount_fee,
              amount: this.new_amount,
              billers_fee: this.billers_amount_fee,
              processing_fee: this.processing_amount_fee,
              is_franchise: this.is_franchise,
              total: this.total_amount,
              payor: this.payor.toUpperCase(),
              account: this.account,
            })
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: response.data,
                  })
                } else {
                  this.change_snackbar({
                    show: true,
                    color: color,
                    text: 'TRANSACTION SUCCESSFULLY',
                  })
                  // this.print_receipt(response.data)
                }
                this.saving = false
                this.description = ''
                this.$emit('data', true)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      date_format(value) {
        return moment(value)
      },
    },
  }
</script>
