<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center text-uppercase">
            Option
          </th>
          <th class="text-center text-uppercase">
            Category
          </th>
          <th class="text-center text-uppercase">
            Particulars
          </th>
          <th class="text-center text-uppercase">
            Date
          </th>
          <th class="text-center text-uppercase">
            Transaction Ref. #
          </th>
          <th class="text-center text-uppercase">
            Amount
          </th>
          <th class="text-center text-uppercase">
            Biller Fee
          </th>
          <th class="text-center text-uppercase">
            Processing Fee
          </th>
          <th class="text-center text-uppercase">
            System Fee
          </th>
          <th class="text-center text-uppercase">
            Total
          </th>
          <th class="text-center text-uppercase">
            Status
          </th>
          <th class="text-center text-uppercase">
            Remarks
          </th>
          <th class="text-center text-uppercase">
            Print
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in liquidations"
          :key="item.id"
        >
          <td class="text-center">
            {{ item.category
            }}
          </td>
          <td class="text-center">
            {{ item.particulars.category }}
          </td>
          <td class="text-center">
            {{ item.particulars.particulars }}
          </td>
          <td class="text-center">
            {{ date_format(item.date_time).format('MMMM DD, YYYY HH:mm:ss') }}
          </td>
          <td class="text-center">
            {{ item.reference_no }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.amount) }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.billers_fee) }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.processing_fee) }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.fee) }}
          </td>
          <td class="text-center">
            {{ formatPrice(item.total) }}
          </td>
          <td class="text-center">
            <v-chip
              v-if="item.deposit_slip_id!=null"
              small
              color="success"
              class="font-weight-medium"
            >
              DEPOSITED
            </v-chip>
            <v-chip
              v-else
              small
              color="error"
              class="font-weight-medium"
            >
              PENDING
            </v-chip>
          </td>
          <td class="text-center">
            <v-chip
              v-if="item.is_approved"
              small
              color="success"
              class="font-weight-medium"
            >
              HO APPROVED
            </v-chip>
            <v-chip
              v-else
              small
              color="error"
              class="font-weight-medium"
            >
              HO PENDING
            </v-chip>
          </td>
          <td class="text-center">
            <v-icon
              v-if="!is_deleting && !item.is_print && item.is_approved"
              class="mr-2"
              color="info"
              @click="print_receipt(item)"
            >
              {{icons.mdiPrinter}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-if="is_deleting"></v-progress-circular>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </div>
</template>

<script>
  import {mdiDelete, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";

  export default {
    setup() {
      return {
        icons: {
          mdiDelete,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    props: {
      liquidations: Array,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'month_of', 'user_id', 'is_franchise', 'branch', 'branch_address', 'branch_contact_no']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('fund_liquidation', ['load_printed_liquidate']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },

      async print_receipt(datum) {
        this.is_deleting=true
        await this.load_printed_liquidate({
          id: datum.id
        })
        var title_array = []
        var head_array = []
        var data_array = []
        var foot_array = []
        var witdss = [92, 92]
        var witdssTi = [192]
        title_array.push(
          [
            {image: this.company_logo, width: 192, height: 60, style: 'logo'},
          ]
        )
        title_array.push(
          [
            {text: this.branch_address, style: 'subheader'},
          ]
        )
        title_array.push(
          [
            {
              text: 'TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
              style: 'subheader',
            },
          ]
        )
        head_array.push(
          [
            {text: 'Branch: ' + this.branch, alignment: 'left'},
          ]
        )
        head_array.push(
          [
            {text: 'Name: ' + datum.payor.toUpperCase(), alignment: 'left'},
          ]
        )
        head_array.push(
          [
            {text: 'Ref No.: ' + datum.reference_no, alignment: 'left'},
          ]
        )
        head_array.push(
          [
            {
              text: this.date_format(datum.date_time).format('MMMM DD, YYYY HH:mm:ss'),
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Service Type:', alignment: 'left'},
            {text: datum.particulars.option, alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Category:', alignment: 'left'},
            {text: datum.particulars.category, alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Particulars:', alignment: 'left'},
            {text: datum.particulars.particulars, alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Account:', alignment: 'left'},
            {text: datum.account, alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Amount:', alignment: 'left'},
            {text: this.formatPrice(datum.amount), alignment: 'left'},
          ]
        )
        data_array.push(
          [
            {text: 'Convenience Fee:', alignment: 'left'},
            {
              text: this.formatPrice(parseFloat(datum.processing_fee) + parseFloat(datum.fee) + parseFloat(datum.billers_fee)),
              alignment: 'left'
            },
          ]
        )
        data_array.push(
          [
            {text: 'Total:', alignment: 'left'},
            {
              text: this.formatPrice(datum.total),
              alignment: 'left'
            },
          ]
        )
        foot_array.push(
          [
            {text: 'WE BRING YOUR MONEY TO LIFE', alignment: 'center'},
          ]
        )
        foot_array.push(
          [
            {text: 'Thank you and Come Again', alignment: 'center'},
          ]
        )
        foot_array.push(
          [
            {text: 'CUSTOMERS COPY', alignment: 'center'},
          ]
        )

        var title_array2 = []
        var head_array2 = []
        var data_array2 = []
        var foot_array2 = []
        var witdss2 = [92, 92]
        var witdssTi2 = [192]
        title_array2.push(
          [
            {image: this.company_logo, width: 192, height: 60, style: 'logo'},
          ]
        )
        title_array2.push(
          [
            {text: this.branch_address, style: 'subheader'},
          ]
        )
        title_array2.push(
          [
            {
              text: 'TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
              style: 'subheader',
            },
          ]
        )
        head_array2.push(
          [
            {text: 'Branch: ' + this.branch, alignment: 'left'},
          ]
        )
        head_array2.push(
          [
            {text: 'Name: ' + datum.payor.toUpperCase(), alignment: 'left'},
          ]
        )
        head_array2.push(
          [
            {text: 'Ref No.: ' + datum.reference_no, alignment: 'left'},
          ]
        )
        head_array2.push(
          [
            {
              text: this.date_format(datum.date_time).format('MMMM DD, YYYY HH:mm:ss'),
              alignment: 'left'
            },
          ]
        )
        data_array2.push(
          [
            {text: 'Service Type:', alignment: 'left'},
            {text: datum.particulars.option, alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Category:', alignment: 'left'},
            {text: datum.particulars.category, alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Particulars:', alignment: 'left'},
            {text: datum.particulars.particulars, alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Account:', alignment: 'left'},
            {text: datum.account, alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Amount:', alignment: 'left'},
            {text: this.formatPrice(datum.amount), alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Billers Fee:', alignment: 'left'},
            {text: this.formatPrice(datum.billers_fee), alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Processing Fee:', alignment: 'left'},
            {
              text: this.formatPrice(datum.processing_fee),
              alignment: 'left'
            },
          ]
        )
        data_array2.push(
          [
            {text: 'System Fee:', alignment: 'left'},
            {text: this.formatPrice(datum.fee), alignment: 'left'},
          ]
        )
        data_array2.push(
          [
            {text: 'Total:', alignment: 'left'},
            {
              text: this.formatPrice(datum.total),
              alignment: 'left'
            },
          ]
        )
        foot_array2.push(
          [
            {text: 'WE BRING YOUR MONEY TO LIFE', alignment: 'center'},
          ]
        )
        foot_array2.push(
          [
            {text: 'Thank you and Come Again', alignment: 'center'},
          ]
        )
        foot_array2.push(
          [
            {text: 'GL-EXPRESS COPY', alignment: 'center'},
          ]
        )
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs === undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageOrientation: 'portrait',
          pageMargins: 1,
          content: [
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 12,
              },
              table: {
                widths: witdssTi,
                body: title_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
              },
              table: {
                widths: witdssTi,
                body: head_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdss,
                body: data_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex % 2 == 0) ? '#bae8cc' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdssTi,
                body: foot_array,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 12,
              },
              pageBreak: "before",
              table: {
                widths: witdssTi2,
                body: title_array2,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
              },
              table: {
                widths: witdssTi2,
                body: head_array2,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdss2,
                body: data_array2,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex % 2 == 0) ? '#bae8cc' : null;
                },
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
            {
              style: {
                margin: [0, 2, 0, 2],
                fontSize: 9,
              },
              table: {
                widths: witdssTi2,
                body: foot_array2,
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.body.length) ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                  return (i == 0 || i === node.table.widths.length) ? 1 : 0;
                },
              },
            },
          ],
          footer: {},
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 7,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 8,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        var win = window.open('', '', "width=1000,height=500");
        pdfMake.createPdf(docDefinition).print({}, win)
        this.is_deleting=false
        this.$emit('data', true)
      },
    },
  }
</script>
